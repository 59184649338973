import axios from "axios";

const state = {
  loading: 0, // 0 - not loading, 1 - live loading, 2 full loading
  auth: {
    user: JSON.parse(localStorage.getItem("user")) || null,
    token: JSON.parse(localStorage.getItem("token")) || null,
  },
  account: JSON.parse(localStorage.getItem("account")) || null,
};

const getters = {
  getToken(state) {
    return state.auth.token;
  },
  getUser(state) {
    return state.auth.user;
  },
  loggedIn(state) {
    return state.auth.token !== null;
  },
  getAccount(state) {
    return state.account;
  }
};

const actions = {
  setLoading({ commit }, loading) {
    commit("SET_LOADING", loading);
  },

  login({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("auth/login", credentials)
        .then((response) => {
          // Check for token in API response
          if (response.data.access_token) {
            // Add token to axios default headers
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.access_token;

            //Save user to local storage
            //localStorage.setItem("user", JSON.stringify(response.data.user));

            // Save token to local storage
            localStorage.setItem("token", JSON.stringify(response.data.access_token));

            // Commit the user to //Vuex Store
            //commit("SET_USER", response.data.user);

            // Commit the token to Vuex Store
            commit("SET_TOKEN", response.data.access_token);
            resolve(response);

            //Vue.toasted.success('Logged in successfully.');
          }
        })
        .catch((error) => {
          if (error.response.data.errors) {
            // console.log(error.response.data.errors);
            // for (const [key, errors] of Object.entries(error.response.data.errors)) {
            //   errors.forEach((error) => {
            //     //Vue.toasted.error(error);
            //   });
            // }
          }

          if (error.response.data.error) {
            //Vue.toasted.error(error.response.data.error_description)
          }

          reject(error);
        });
    });
  },

  user({commit},credentials){
    return new Promise((resolve, reject) => {
      axios
        .get("auth/me", credentials)
        .then((response) => {
          // Check for token in API response
          if (response.data.name) {

            //Save user to local storage
            localStorage.setItem("user", JSON.stringify(response.data));

            // Commit the user to //Vuex Store
            commit("SET_USER", response.data);

            resolve(response);

            //Vue.toasted.success('Logged in successfully.');
          }
        })
        .catch((error) => {
          if (error.response.data.errors) {
            // console.log(error.response.data.errors);
            // for (const [key, errors] of Object.entries(error.response.data.errors)) {
            //   errors.forEach((error) => {
            //     //Vue.toasted.error(error);
            //   });
            // }
          }

          if (error.response.data.error) {
            //Vue.toasted.error(error.response.data.error_description)
          }

          reject(error);
        });
    });
  },
  setAccount({commit},account){
    localStorage.setItem("account", JSON.stringify(account));
    commit("SET_ACCOUNT", account);
  },

  async logout({ commit }) {
    if (this.getters.loggedIn) {
       commit('DESTROY_TOKEN');
       localStorage.removeItem('token');
       localStorage.removeItem("user");
      // //Vue.toasted.success('Logged out successfully.');
       window.location.reload();

      // await axios.post("auth/logout").then(() => {
      //     commit("DESTROY_TOKEN");
      //     //localStorage.removeItem("token");
      //     //localStorage.removeItem("user");
      //     //Vue.toasted.success('Logged out.');
      //   })
      //   .catch((error) => {
      //     console.log(error.response.data);

      //     // Unauthorized/token expired
      //     if (error.response.status === 401) {
      //       commit("DESTROY_TOKEN");
      //       //localStorage.removeItem("token");
      //       //localStorage.removeItem("user");
      //       ////Vue.toasted.success('Logged out.');
      //     } else {
      //       //Vue.toasted.error('Error logging out.');
      //     }
      //   });
    }
  },
  async logoutAccount({commit}) {
    if(this.getters.loggedIn) {
      commit('DESTROY_ACCOUNT');
      localStorage.removeItem('account');
    }
  }
};

const mutations = {
  SET_TOKEN(state, token) {
    state.auth.token = token;
  },
  SET_USER(state, user) {
    state.auth.user = user;
  },
  SET_ACCOUNT(state, account) {
    state.auth.account = account;
  },
  DESTROY_TOKEN(state) {
    state.auth.token = null;
    state.auth.user = null;
  },
  DESTROY_ACCOUNT(state) {
    state.auth.account = null;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
