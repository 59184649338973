import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/invitation/:token",
    name: "Invitation",
    component: () => import(/* webpackChunkName: "Invitation" */ "../views/Invitation.vue"),
  },
  {
    path: "/",
    redirect: "/account",
  },
  {
    path: "/account",
    name: "Account",
    component: () => import(/* webpackChunkName: "Account" */ "../views/Account.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/main/:account_id",
    name: "Main",
    component: () => import(/* webpackChunkName: "Main" */ "../views/Main.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "resources/:resources_id",
        name: "Resources",
        component: () =>
          import(/* webpackChunkName: "Resources" */ "../views/resources/Resources.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "resources/:resources_id/level/:level_id",
        name: "Resources Level",
        component: () =>
          import(/* webpackChunkName: "Resources Level" */ "../views/resources/ResourcesLevel.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "resources/:resources_id/resource/:resource_id",
        name: "Resource",
        component: () =>
          import(/* webpackChunkName: "Resource" */ "../views/resources/Resource.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "folders/:folders_id",
        name: "Folders",
        component: () => import(/* webpackChunkName: "Folder" */ "../views/folders/Folder.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "folders/:folders_id/resource/:resource_id",
        name: "Resursa Folder",
        component: () =>
          import(/* webpackChunkName: "Resursa Folder" */ "../views/resources/Resource.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "editor/:id",
        name: "Editor Resurse",
        component: () =>
          import(/* webpackChunkName: "Resursa Folder" */ "../views/editor/Editor.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "editor/new",
        name: "Editor Resurse",
        component: () =>
          import(/* webpackChunkName: "Resursa Folder" */ "../views/editor/Editor.vue"),
        meta: { requiresAuth: true },
      },

    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (router.app.$store.getters.loggedIn === false) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

    if (router.app.$store.getters.loggedIn === true) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
