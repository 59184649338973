import Axios from "axios";
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Toasted from "vue-toasted";
import VueAuthImage from "vue-auth-image";
import VueKnova from 'vue-konva';
import AOS from "aos";
import "aos/dist/aos.css";
import "./assets/tailwind.css";
import "./assets/style.scss";
import "./assets/editor.scss";

window.Vue = Vue;

Vue.use(VueAuthImage);

// Axios config
Vue.prototype.$axios = Axios;
Axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
Axios.defaults.baseURL = "https://chartarium.weareomni.eu/api/app";

const token = localStorage.getItem("token");

if (token) {
  const auth = JSON.parse(token);
  Axios.defaults.headers.common["Authorization"] = "Bearer " + auth;
}

Axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

//Global Account
const account = localStorage.getItem('account')
Vue.prototype.$account = JSON.parse(account);

// Responses
Axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  function (error) {
    // Too Many Requests
    // Error 429
    if (error.response.status === 429) {
      console.log("too many requests");
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // 401 Unauthorized
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

// Toasted config
Vue.use(Toasted, {
  theme: "toasted-primary",
  position: "top-center",
  duration: 3000,
});

Vue.config.productionTip = false;

// Konva Config
Vue.use(VueKnova);

new Vue({
  store,
  router,
  vuetify,
  created() {
    AOS.init({
      duration: 300,
      easing: "ease",
      once: true,
      offset: 0,
      anchorPlacement: "top",
    });
  },
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
